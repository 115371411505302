import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import VueNativeSock from 'vue-native-websocket'
import '@mkody/vue-material-design-icons/styles.css'

const rbPopApp = createApp(App)
rbPopApp.use(store)
rbPopApp.use(
  VueNativeSock,
  'wss://node.radiobrony.fr',
  {
    format: 'json',
    reconnection: true,
    reconnectionDelay: 10000, // 10 seconds
    store: store
  }
)
rbPopApp.mount('#app')
