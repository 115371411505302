import { createStore } from 'vuex'

function tr (state, message, npc) {
  // Hide data, let the animation end, set value and display it again
  if (state.dataDisplay.npc !== npc) {
    if (state.dataDisplay.temp !== npc) {
      // This locks for double-transitions
      state.dataDisplay.temp = npc

      setTimeout(_ => {
        state.dataDisplay.showNP = false

        setTimeout(_ => {
          state.tracks = message.tracks
          state.meta = message.meta
          state.dataDisplay.showNP = true
          state.dataDisplay.npc = npc
        }, 600)

        if ('mediaSession' in navigator && navigator.mediaSession.metadata !== null) {
          navigator.mediaSession.metadata.title = message.tracks.current.title.title
          navigator.mediaSession.metadata.artist = message.tracks.current.title.artist
        }
      }, state.dataDisplay.delay)
    }
  } else {
    state.tracks = message.tracks
    state.meta = message.meta
  }
}

export default createStore({
  state: {
    i: 0,
    serverDelay: 0,
    socket: {
      isConnected: false,
      reconnectError: false
    },
    tracks: {
      previous: {},
      current: {
        title: {
          artist: 'Bienvenue',
          title: 'sur Radio Brony'
        },
        elapsed: {
          string: '00:00',
          seconds: 0
        },
        duration: {
          string: '00:00',
          seconds: 0
        },
        start: {
          string: '00:00:00',
          ts: 0
        },
        end: {
          string: '00:00:00',
          ts: 0
        }
      },
      next: {}
    },
    meta: {
      cover: 'https://radiobrony.fr/img/logos/RBLogo.png',
      covers: {
        orig: 'https://t.radiobrony.fr/JnZ_Cc6PQ_NHVjlDi46Y83EjApY=/https://radiobrony.fr/img/logos/RBLogo.png',
        small: 'https://t.radiobrony.fr/haTYp79hNIMql976V1eGmp3cOlw=/0x140/https://radiobrony.fr/img/logos/RBLogo.png',
        medium: 'https://t.radiobrony.fr/9emHFxP5KnVaQAlzsqz4mgY3G88=/0x280/https://radiobrony.fr/img/logos/RBLogo.png',
        large: 'https://t.radiobrony.fr/yXleDDxKkgtTx1wZRu7WmPz3iok=/0x420/https://radiobrony.fr/img/logos/RBLogo.png'
      },
      status: null,
      listeners: 9001,
      team: []
    },
    live: {
      html: '<i>Chargement...</i>'
    },
    dataDisplay: {
      npc: '',
      temp: '',
      firstLoading: true,
      showNP: true,
      delay: 5000
    }
  },
  mutations: {
    setDelay (state, ms) {
      // Buffer delay + delay with server time + internal 10s delay
      state.dataDisplay.delay = ms - state.serverDelay + 10000
    },
    SOCKET_ONOPEN (state, event) {
      state.socket.isConnected = true
    },
    SOCKET_ONCLOSE (state, event) {
      state.socket.isConnected = false
    },
    SOCKET_ONERROR (state, event) {
      console.error('Socket error', event, state)
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE (state, message) {
      if ('ts' in message) {
        state.serverDelay = message.ts - Date.now()
        console.log('Current time difference with server (in ms):', state.serverDelay)
      } else {
        const npc = message.tracks.current.title.artist + ' - ' + message.tracks.current.title.title

        if (state.tracks !== message.tracks || state.meta !== message.meta) {
          tr(state, message, npc)
        }

        if (state.live !== message.live) {
          state.live = message.live
        }
      }
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT (state, count) {
      console.info('Socket reconnect, try #' + count, state)
    },
    SOCKET_RECONNECT_ERROR (state) {
      state.socket.reconnectError = true
    }
  }
})
