<template>
  <div class="radio">
    <div class="player">
      <div class="cover">
        <transition name="fade">
          <img
            :key="liveMeta.cover"
            alt="Radio Brony"
            :srcset="`${liveMeta.covers.small},
                      ${liveMeta.covers.medium} 2x,
                      ${liveMeta.covers.large} 3x`"
            :src="liveMeta.covers.large"/>
        </transition>
      </div>

      <div class="progress">
        <div
          :style="{ width: playerStatus.progress + '%' }"
          class="bar" />
      </div>

      <div class="liverb-player">
        <div class="ctrl">
          <span
            v-if="!playerStatus.playing && !playerStatus.buffering"
            class="play-stop"
            @click="playerPlay()">
            <play-icon title="Lancer la lecture"/>
          </span>
          <span
            v-else-if="playerStatus.buffering"
            class="buffering">
            <timer-sand-empty-icon title="Chargement..."/>
          </span>
          <span
            v-else
            class="play-stop"
            @click="playerStop()">
            <stop-icon title="Arrêter la lecture"/>
          </span>
          <span
            v-if="!playerStatus.muted"
            class="mute"
            @click="playerMute()">
            <volume-high-icon title="Couper le son"/>
          </span>
          <span
            v-if="playerStatus.muted"
            class="mute"
            @click="playerUnmute()">
            <volume-mute-icon title="Activer le son"/>
          </span>
        </div>
        <div class="vol">
          <input
            v-model="playerStatus.volume"
            aria-label="Volume" type="range"
            min="0" max="1" step="0.05"
            @input="playerVolume" @change="saveVolume">
        </div>
      </div>
    </div>
    <transition appear name="slide">
      <div v-if="showTitle" class="text">
        <transition name="slide">
          <div
            v-if="liveMeta.status"
            class="status"
            v-text="liveMeta.status" />
        </transition>
        <div
          class="artist"
          v-text="np.title.artist" />
        <div
          class="title"
          v-text="np.title.title" />
      </div>
    </transition>
  </div>
</template>

<script>
// Deps
import videojs from 'video.js'

// Icons
import PlayIcon from '@mkody/vue-material-design-icons/Play.vue'
import StopIcon from '@mkody/vue-material-design-icons/Stop.vue'
import TimerSandEmptyIcon from '@mkody/vue-material-design-icons/TimerSandEmpty.vue'
import VolumeHighIcon from '@mkody/vue-material-design-icons/VolumeHigh.vue'
import VolumeMuteIcon from '@mkody/vue-material-design-icons/VolumeMute.vue'

export default {
  name: 'App',
  components: {
    PlayIcon,
    StopIcon,
    TimerSandEmptyIcon,
    VolumeHighIcon,
    VolumeMuteIcon
  },
  data () {
    return {
      playerStatus: {
        playing: false,
        buffering: false,
        muted: false,
        volume: 0.25,
        savedVol: 0.25,
        progress: 0
      },
      intervals: {
        marqueeArtist: null,
        marqueeTitle: null,
        progress: null
      },
      aObj: null,
      skipHls: false,
      vjs: null
    }
  },
  computed: {
    np () {
      return this.$store.state.tracks.current
    },
    liveMeta () {
      return this.$store.state.meta
    },
    showTitle () {
      return this.$store.state.dataDisplay.showNP
    },
    streamDelay () {
      return this.$store.state.dataDisplay.delay
    },
    serverDelay () {
      return this.$store.state.serverDelay
    }
  },
  unmounted () {
    // Clear all the intervals
    if (this.intervals.marqueeArtist) clearInterval(this.intervals.marqueeArtist)
    if (this.intervals.marqueeTitle) clearInterval(this.intervals.marqueeTitle)
    if (this.intervals.progress) clearInterval(this.intervals.progress)
    // Dispose VideoJS if loaded
    if (this.vjs && !this.vjs.isDisposed()) {
      this.vjs.dispose()
    } else {
      this.aObj.src = null
    }
  },
  mounted () {
    try {
      const lsVol = parseFloat(localStorage.getItem('savedVolume'))

      if (!isNaN(lsVol)) {
        this.playerStatus.savedVol = this.playerStatus.volume = lsVol
      }
    } catch (e) {
      console.log('Error getting savedVolume.', e)
    }

    // Launch once and check if we enable/disable night theme every 15 minutes
    this.dayOrNight(true)
    setInterval(this.dayOrNight, 15 * 60 * 1000)

    // Check for seasonal theme
    this.seasonTheme()

    this.intervals.marqueeArtist = this.marquee('artist')
    this.intervals.marqueeTitle = this.marquee('title')
    this.intervals.progress = setInterval(this.updateProgress, 1000)

    // Create our audio element
    this.aObj = document.createElement('audio')
    this.aObj.preload = 'none'
    this.aObj.volume = this.playerStatus.volume

    // Catch errors when HLS fails
    videojs.hook('error', (_, err) => {
      if ('code' in err && err.code === 4 && !this.skipHls) this.switchToIcecast()
    })
    videojs.log.level('error') // show only error messages and suppress others

    // Create our VideoJS object
    this.vjs = videojs(this.aObj, {
      html5: {
        vhs: {
          useNetworkInformationApi: true,
          useBandwidthFromLocalStorage: true
        }
      },
      sources: [
        {
          src: 'https://radiobrony.live/hls/live.m3u8',
          type: 'application/x-mpegURL'
        }
      ]
    })

    // Catch when HLS fails too much
    this.vjs.on('vhs-error-reload-canceled', this.switchToIcecast)

    document.addEventListener('playRadio', this.playerPlay, false)

    const getTs = setInterval(_ => {
      if (this.$socket.readyState === 1) {
        clearInterval(getTs)
        // Get server time to find delay
        this.$socket.send('srvtime')
      }
    }, 100)

    if (!window.location.hash.includes('dark')) {
      document.querySelector('body').classList.add('dark')
    }

    if (window.location.hash.includes('autoplay')) {
      setTimeout(_ => {
        this.playerPlay()
      }, 1000)
    }
  },
  methods: {
    dayOrNight (first) {
      // Add or remove 'night' class based on the time
      const cL = document.querySelector('body').classList
      const h = (new Date()).getUTCHours()

      // Night at UTC: summer: 18~6, winter: 16~9
      if (h < 9 || h >= 16) {
        cL.add('night')
      } else if (cL.contains('night')) {
        cL.remove('night')
      }
    },
    seasonTheme () {
      // Enable halloween or xmas themes based on month
      const cL = document.querySelector('body').classList
      const m = (new Date()).getMonth()

      if (m === 9) { // October
        cL.add('halloween')
      } else if (m === 11) { // December
        cL.add('xmas')
      }
    },
    updateProgress () {
      if (this.np.duration.seconds <= 0 || this.liveMeta.status === 'LIVE' || this.liveMeta.status === 'INFOS') {
        this.playerStatus.progress = 0
        return
      }

      // Not sure if we need to apply serverDelay here too?
      const progress = ((Date.now() + this.serverDelay - this.np.start.ts - this.streamDelay) / (this.np.end.ts - this.np.start.ts)) * 100
      this.playerStatus.progress = progress >= 100 ? 100 : progress <= 0 ? 0 : progress
    },
    switchToIcecast () {
      console.log('Playback error, switching to native + icecast')

      // Dispose of VideoJS
      if (!this.vjs.isDisposed()) this.vjs.dispose()
      this.skipHls = true

      // Recreate our audio element
      this.aObj = document.createElement('audio')
      this.aObj.volume = this.playerStatus.volume

      // Switch to normal stream
      if (this.aObj.canPlayType('audio/aac') !== '') {
        console.log('Setting aac')
        this.aObj.src = 'https://radiobrony.live/aac'
      } else if (this.aObj.canPlayType('audio/mp3') !== '') {
        console.log('Setting mp3')
        this.aObj.src = 'https://radiobrony.live/mp3'
      } else {
        console.log('Setting ogg')
        this.aObj.src = 'https://radiobrony.live/ogg'
      }

      // Retry playing
      this.playerPlay()
    },
    playerPlay () {
      const isHls = !this.skipHls
      const startEvent = new Event('live:play')
      document.dispatchEvent(startEvent)

      const dS = Date.now()
      this.playerStatus.buffering = true

      if (this.skipHls) {
        this.aObj.load()
      } else if (this.vjs && this.vjs.liveTracker.isTracking()) {
        this.vjs.liveTracker.seekToLiveEdge()
      }

      const pP = this.aObj.play()

      if (pP !== undefined) {
        pP
          .then(_ => {
            this.playerStatus.buffering = false
            this.playerStatus.playing = true
            this.$store.commit('setDelay', Date.now() - dS)

            try { // Apparently FF return true and then the code crashes
              if ('mediaSession' in navigator) {
                // eslint-disable-next-line no-undef
                navigator.mediaSession.metadata = new MediaMetadata({
                  title: this.np.title.title,
                  artist: this.np.title.artist,
                  album: 'Radio Brony',
                  artwork: [{ src: 'https://radiobrony.fr/img/icons/bw-off.png' }]
                })

                navigator.mediaSession.setActionHandler('play', this.playerPlay)
                navigator.mediaSession.setActionHandler('pause', this.playerStop)
                navigator.mediaSession.setActionHandler('seekbackward', null)
                navigator.mediaSession.setActionHandler('seekforward', null)
                navigator.mediaSession.setActionHandler('seekto', null)
              }
            } catch (_) {
              // Silently ignore
            }
          })
          .catch(e => {
            console.log('Could not start playing:', e)
            if (!isHls) this.playerStatus.buffering = false
            this.playerStatus.playing = false
          })
      } else {
        this.playerStatus.buffering = false
        this.playerStatus.playing = true
        this.$store.commit('setDelay', Date.now() - dS)
      }
    },
    playerStop () {
      if (!this.playerStatus.buffering) {
        if (this.skipHls || !this.vjs.isDisposed()) {
          this.aObj.pause()
        } else {
          // It's broken
          console.log('Tried to stop but does the player exist?')
          return
        }

        this.playerStatus.playing = false
      }
    },
    playerMute () {
      this.playerStatus.volume = 0
      this.playerStatus.muted = true
      this.aObj.volume = 0
    },
    playerUnmute () {
      this.playerStatus.muted = false
      this.playerStatus.volume = this.playerStatus.savedVol
      this.aObj.volume = this.playerStatus.savedVol
    },
    playerVolume () {
      this.aObj.volume = this.playerStatus.volume
      this.playerStatus.muted = (this.playerStatus.volume < 0.05)
    },
    saveVolume () {
      if (this.playerStatus.volume > 0.01) {
        this.playerStatus.savedVol = this.playerStatus.volume

        try {
          localStorage.setItem('savedVolume', this.playerStatus.volume)
        } catch (e) {
          console.log('Error setting savedVolume.', e)
        }
      }
    },
    marquee (name) {
      let off = 0
      let cooloff = 0

      function scroll (sel) {
        const tEl = sel.querySelector('.radio .text')
        const el = sel.querySelector('.radio .' + name)

        // If elements dont't exist, skip
        if (!tEl || !el) return

        const width = el.offsetWidth
        const pWidth = tEl.offsetWidth

        if (width > pWidth) {
          if (cooloff > 0) {
            cooloff--
          } else if (-off > width) {
            off = pWidth
          } else if (-off === 1) {
            cooloff = 800 // 8 seconds
            off--
          } else {
            el.style.marginLeft = off + 'px'
            off--
          }
        } else {
          off = 0
          cooloff = 0
          el.style.marginLeft = 0
        }

        requestAnimationFrame(_ => { scroll(sel) })
      }

      requestAnimationFrame(_ => { scroll(this.$el) })
    }
  }
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

body {
  background: transparent !important;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  margin: 0;
  overflow: hidden;
  padding: 0;

  &.xmas,
  &.xmas.night {
    .radio {
      background-image: url('./assets/xmas.ban.jpg');
      background-position: top right;

      .progress {
        .bar {
          background-color: #FFD700;
        }
      }

      .liverb-player {
        input[type=range] {
          &::-webkit-slider-runnable-track {
            background: #FFD700;
          }

          &::-webkit-slider-thumb {
            background: #fff;
          }

          &::-moz-range-track {
            background: #FFD700;
          }

          &::-moz-range-thumb {
            background: #fff;
          }

          &::-ms-fill-lower, &::-ms-fill-upper {
            background: #FFD700;
          }

          &::-ms-thumb {
            background: #fff;
          }

          &:focus {
            &::-ms-fill-lower {
              background: #FFD700;
            }
          }
        }
      }
    }
  }

  &.halloween,
  &.halloween.night {
    .radio {
      background-image: url('./assets/halloween.ban.jpg');
      background-position: top right;

      .progress {
        .bar {
          background-color: #F84C09;
        }
      }

      .liverb-player,
      .text div {
        background-color: rgba(0, 0, 0, .7);
        color: #F84C09;
      }

      .liverb-player {
        input[type=range] {
          &::-webkit-slider-runnable-track {
            background: #F84C09;
          }

          &::-webkit-slider-thumb {
            background: #fff;
          }

          &::-moz-range-track {
            background: #F84C09;
          }

          &::-moz-range-thumb {
            background: #fff;
          }

          &::-ms-fill-lower,
          &::-ms-fill-upper {
            background: #F84C09;
          }

          &::-ms-thumb {
            background: #fff;
          }

          &:focus {
            &::-ms-fill-lower {
              background: #F84C09;
            }
          }
        }
      }
    }
  }
}

body.night {
  .radio {
    background-image: url('./assets/night.ban.jpg');
    background-position: top right;
  }
}

body.dark {
  // Base color stuff
  background-color: #292829;
  color: #ffffff;

  // Change text colors in header
  .radio {
    color: #ffffff;

    .text {
      div {
        background-color: rgba(0, 0, 0, 0.7);
      }
    }
  }

  // Change player colors
  .liverb-player {
    background-color: rgba(0, 0, 0, .7);

    input[type=range] {
      &::-webkit-slider-runnable-track {
        background: #F53B49;
      }

      &::-moz-range-track {
        background: #F53B49;
      }

      &::-ms-fill-lower,
      &::-ms-fill-upper {
        background: #F53B49;
      }

      &:focus {
        &::-ms-fill-lower {
          background: #F53B49;
        }
      }
    }
  }
}

.radio {
  background-color: #a6d9f0;
  background-image: url('./assets/day.ban.jpg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #000000;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: calc(100vh - 15px);
  margin: 0;
  padding: 0 15px 15px;

  .slide-enter-active,
  .slide-leave-active {
    transition: margin-top 1s, opacity .8s;
  }

  .slide-enter-from,
  .slide-leave-to {
    margin-top: -20px;
    opacity: 0;
  }

  .progress {
    width: 100%;

    .bar {
      background-color: #F53B49;
      content: ' ';
      height: 3px;
      transition: width .5s;
    }
  }

  .player {
    align-self: center;
    flex: 0;
    padding-right: 15px;

    .cover {
      display: flex;
      height: 140px;
      justify-content: center;
      margin-bottom: -3px;
      width: 248px;

      img {
        align-self: center;
        max-height: 140px;
        // max-width: 248px;
        position: absolute;
      }
    }

    audio {
      width: 100%;
    }
  }

  .text {
    align-content: center;
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 15px;
    overflow: hidden;

    div {
      align-self: auto;
      background-color: rgba(255, 255, 255, 0.8);
      flex: 0 1 auto;
      font-size: 30px;
      overflow: hidden;
      padding: 2px 5px;
      white-space: nowrap;
    }

    .status {
      color: red;
      font-size: 15px;
      font-weight: bold;
      order: 1;
    }

    .artist {
      font-size: 30px;
      font-weight: bold;
      order: 2;
    }

    .title {
      order: 3;
    }
  }
}

.liverb-player {
  background-color: rgba(255, 255, 255, .8);
  display: flex;

  .ctrl {
    font-size: 25px;
    line-height: 28px;
    margin-bottom: 2px;
    margin-left: 5px;
    text-align: center;
    white-space: nowrap;

    .play-stop,
    .buffering,
    .mute {
      cursor: pointer;
      margin: 0 5px;
    }
  }

  .vol {
    flex-grow: 1;
    margin: 5px 15px 0 10px;
  }
}

input[type=range] {
  -webkit-appearance: none;
  background: none;
  margin: 0;
  width: 100%;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    background: #000000;
    border-radius: 1px;
    border: 0px solid #18d501;
    box-shadow: 0px 0px 1.7px #002200, 0px 0px 0px #003c00;
    cursor: pointer;
    height: 7px;
    width: 100%;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #fff;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, .5);
    box-shadow: 2.6px 2.6px 3.7px rgba(0, 170, 0, 0), 0px 0px 2.6px rgba(0, 195, 0, 0);
    cursor: pointer;
    height: 20px;
    margin-top: -6.5px;
    width: 20px;
  }

  &:focus::-webkit-slider-runnable-track {
    background: #0a0a0a;
  }

  &::-moz-range-track {
    background: #000000;
    border-radius: 1px;
    border: 0px solid #18d501;
    box-shadow: 0px 0px 1.7px #002200, 0px 0px 0px #003c00;
    cursor: pointer;
    height: 7px;
    width: 100%;
  }

  &::-moz-range-thumb {
    background: #fff;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, .5);
    box-shadow: 2.6px 2.6px 3.7px rgba(0, 170, 0, 0), 0px 0px 2.6px rgba(0, 195, 0, 0);
    cursor: pointer;
    height: 20px;
    width: 20px;
  }

  &::-ms-track {
    background: transparent;
    border-color: transparent;
    color: transparent;
    cursor: pointer;
    height: 7px;
    width: 100%;
  }

  &::-ms-fill-lower, &::-ms-fill-upper {
    background: #000000;
    border-radius: 2px;
    border: 0px solid #18d501;
    box-shadow: 0px 0px 1.7px #002200, 0px 0px 0px #003c00;
  }

  &::-ms-thumb {
    background: #fff;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, .5);
    box-shadow: 2.6px 2.6px 3.7px rgba(0, 170, 0, 0), 0px 0px 2.6px rgba(0, 195, 0, 0);
    cursor: pointer;
    height: 20px;
    height: 7px;
    width: 20px;
  }

  &:focus {
    &::-ms-fill-lower {
      background: #000000;
    }

    &::-ms-fill-upper {
      background: #0a0a0a;
    }
  }
}

@media (max-width: 719px) {
  .radio {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;

    .progress .bar {
      height: 5px;
    }

    .player {
      max-width: 100%;
      padding: 0;
      width: 248px;

      .cover {
        margin-bottom: -5px;
        max-width: 100%;

        img {
          max-width: calc(100vw - 45px);
        }
      }
    }

    .text {
      flex: initial;
      margin-top: 1em;
    }
  }
}

@media (max-width: 599px) {
  .radio {
    background: transparent !important;
    padding: 15px 0;

    .ctrl {
      margin-left: 0;
    }
  }

  body.dark {
    .liverb-player,
    .text div {
      background-color: rgba(0, 0, 0, .7);
      color: #FFFFFF;
    }

    .progress .bar {
      background: #F53B49;
    }

    input[type=range] {
      &::-webkit-slider-runnable-track {
        background: #F53B49;
      }

      &::-moz-range-track {
        background: #F53B49;
      }

      &::-ms-fill-lower, &::-ms-fill-upper {
        background: #F53B49;
      }

      &:focus {
        &::-ms-fill-lower {
          background: #F53B49;
        }
      }
    }
  }
}

@media (min-width: 600px) {
  .radio {
    flex-direction: row;
    justify-content: initial;

    .player {
      padding-right: 15px;
    }

    .text {
      margin-top: 0;
    }
  }
}

@media (max-height: 294px) and (max-width: 599px) {
  .radio {
    .text {
      display: none;
    }
  }
}
</style>
